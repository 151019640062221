<template>
    <!-- 父盒子 -->
    <div class="father_box" @click="onclick">
      <!-- 生成的标签 -->
      <div v-for="(item, index) in TagsAll" :key="index" class="spanbox">
        <span class="tagspan">{{ item }}</span>
        <i class="span_close" @click="removeTag(index, item)"></i>
      </div>
      <!-- 输入框 -->
      <input
        style="width: 260px;"
        placeholder="Enter and press <Enter> to create the quantity"
        v-model="currentval"
        @keyup.enter="addTags"
        @blur="addTags"
        @keyup.delete="deleteTags"
        :style="inputStyle"
        class="inputTag"
        ref="inputTag"
        type="text"
      />
    </div>
  </template>
   
  <script>
  export default {
    name: "inputTags",
    data() {
      return {
        //输入框
        currentval: "",
        //tag
        TagsAll: [],
        //输入框宽度
        inputLength: "",
        //计算删除位置
        n: 0,
      };
    },
    watch: {
      TagsAll() {
        this.$emit("custom-event", this.TagsAll);
      },
      //监听输入的值越多，输入框越长
      currentval(val) {
        // 实时改变input输入框宽度，防止输入内容超出input默认宽度显示不全
        this.inputLength = this.$refs.inputTag.value.length * 12 + 50;
      },
      // parentArr() {
      //   this.TagsAll = this.parentArr.length ? this.parentArr : [];
      // },
    },
    computed: {
      //计算属性：计算出动态输入框宽度
      inputStyle() {
        let style = {};
        // style.width = `${this.inputLength+170}px`;
        return style;
      },
      // finall() {
      //   return this.TagsAll.join(",");
      // },
    },
    methods: {
      //点击叉叉删除tag
      removeTag(index, item) {
        //console.log(item);
        this.TagsAll.splice(index, 1);
      },
  
      //回车增加tag
      addTags() {
        //新增函数中可以加一些你所需要的校验规则。比如只能是数字，或者不能输入‘，’等
        if (this.currentval) {
          // 只能输入5个数量值
          if (this.TagsAll.length<5) {
            // 只能输入数字
            if (!isNaN(this.currentval)) {
              // 只能输入正整数
              if (this.currentval < 0  || this.currentval.includes('.')) {
                this.$message.error('The quantity value does not conform to the specification - not a positive integer');
              } else {
                // 限制输入长度
                if (this.currentval.length > 10) {
                  this.$message.warning("The value must not exceed 10 characters in length!");
                } else {
                  // 只添加未重复出现的值
                  if (!this.TagsAll.includes(this.currentval)) {
                    //添加tag
                    this.TagsAll.push(this.currentval);
                  }
                }
              }
            } else {
              this.$message.error('The quantity value does not conform to the specification - not a number');
            }
          } else {
            this.$message.warning("Enter a maximum of 5 scalar values");
          }
          //清空输入框
          this.currentval = "";
        }
      },
  
      //键盘删除键删除tag
      deleteTags() {
        if (this.currentval == "") {
          this.n++;
          if (this.n == 2) {
            this.TagsAll.pop();
          }
          if (this.n > 2) {
            if (this.TagsAll.length) {
              this.TagsAll.pop();
            } else {
              this.n = 0;
            }
          }
        } else {
          this.n = 0;
        }
      },
      //点击父盒子输入框获取焦点
      onclick() {
        this.$nextTick(() => {
          this.$refs.inputTag.focus();
        });
      },
    },
  };
  </script>
   
  <style scoped>
  /* 外层div */
  .father_box {
    /* width: 600px; */
    box-sizing: border-box;
    background-color: white;
    border: 1px solid #409EFF;
    border-radius: 4px;
    font-size: 12px;
    text-align: left;
    padding-left: 5px;
    word-wrap: break-word;
    overflow: hidden;
  }
  /* 标签 */
  .spanbox {
    display: inline-block;
    font-size: 14px;
    margin: 3px 4px 3px 0;
    background-color: #ecf5ff;
    border: 1px solid #e8eaec;
    border-radius: 3px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
  }
  /* 标签文字 */
  .tagspan {
    height: 24px;
    line-height: 22px;
    max-width: 99%;
    position: relative;
    display: inline-block;
    padding-left: 8px;
    color: #409EFF;
    font-size: 14px;
    opacity: 1;
    vertical-align: middle;
    overflow: hidden;
    transition: 0.25s linear;
  }
  /* tag的叉叉 */
  .span_close {
    padding: 0 6px 0 4px;
    opacity: 1;
    -webkit-filter: none;
    filter: none;
    color: #409EFF;
    /* font-weight: 600; */
    cursor:pointer;
  }
  /* 鼠标经过叉叉 */
  .span_close:hover{
    background-color: #409EFF;
    border-radius: 50%;
    color: #fff;
  }
  .span_close:after {
    content: "\00D7";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* line-height: 27px; */
    transition: 0.3s, color 0s;
  }
  /* input */
  .inputTag {
    font-size: 16px;
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0;
    width: auto;
    min-width: 150px;
    vertical-align: top;
    height: 32px;
    color: #495060;
    line-height: 32px;
  }
  /* 输入框提示文字大小 */
  input:placeholder-shown {
  font-size: 1.1rem;
  }
  </style>
   
  